<template>
  <div>
    <div class="noticeBox">
      <transition name="slide">
        <div class="notice" :key="text.id">
          <div class="notice_item">
<!--            <img :src="text.val.icon" alt="" class="notice_img">-->
            <span class="notice_content">{{text.val.content}}</span>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ScrollNotice",
    data () {
      return {
        noticeLists: [
          // {icon:require('@/assets/images/notice.png'),content:'1 此处为公告11111111'},
          // {icon:require('@/assets/images/notice.png'),content:'2 此处为公告22222222'},
          // {icon:require('@/assets/images/notice.png'),content:'3 此处为公告33333333'},
          // {icon:require('@/assets/images/notice.png'),content:'4 此处为公告44444444'},
          // {icon:require('@/assets/images/notice.png'),content:'5 此处为公告55555555'},
		  {icon:'',content:'11 此处为公告11111111'},
		  {icon:require('@/assets/images/notice.png'),content:'12 此处为公告22222222'},
		  {icon:require('@/assets/images/notice.png'),content:'13 此处为公告33333333'},
		  {icon:require('@/assets/images/notice.png'),content:'14 此处为公告44444444'},
		  {icon:require('@/assets/images/notice.png'),content:'15 此处为公告55555555'},
        ],
        index: 0,
        timer: null,
        first: true,
        period: '5000'
      }
    },
    computed: {
      text () {
        return {
          id: this.index,
          val: this.noticeLists[this.index]
        }
      }
    },
    mounted () {
      this.Move()
    },
    beforeDestroy(){
      this.timer = null
    }, 
	create(){ 
		 
		this.noticeLists = [];
	},
	show(){ 
	},
    methods: {
      Move () {
        // this.first? this.period = 0 : this.period = '20000'
        this.timer = setTimeout(() => {
          this.first = false
          this.index += 1
          if (this.index>=this.noticeLists.length) {
            this.index = 0
          }
          this.Move()
        }, this.period); // 滚动不需要停顿则将3000改成动画持续时间
      }
    }
  }
</script>

<style scoped>
  .noticeBox {
    width: 100%;
    height: 33px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    text-align: center;
  }
  .notice {
    width: 100%;
    height: 33px;
    position: absolute;
    bottom: 0;
    line-height: 33px;
  }
  .notice_item{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .notice_content{
    line-height: 33px;
    height: 33px;
    color: white;
  }
  .notice_img{
  }
  .slide-enter-active, .slide-leave-active {
    transition: all 3s linear;
  }
  .slide-enter{
    transform: translateX(800px);
    opacity: 0;
  }
  .slide-leave-to {
    transform: translateX(-800px);
    opacity: 0;
  }
</style>
<style scoped lang="scss">
  .noticeBox{
    background-color: $green;
  }

</style>
