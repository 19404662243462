<template>
  <div class="container">
    <ScrollNotice/>
   <div class="poster">
      <el-image :src="require('./img/poster.png')"></el-image>
    </div>
	
    <el-container>
	
      <el-aside width="190px">
        <el-menu
            :default-active="active"
            class="study-menu"
            text-color="#000000"
            @select="handleClick"
            active-text-color="#ffffff">
			
			
          <el-menu-item   :index="menu.id" v-for="menu in menus" :key="menu.id">
            {{ menu.label }}
          </el-menu-item>
		  
		  
		  
        </el-menu>
		
      </el-aside>
      <el-main>
        <router-view/>
      </el-main>
    </el-container>

  </div>
</template>

<script>
  import ScrollNotice from '@/components/ScrollNotice'
  import Book from './Book.vue'

  export default {
    name: "StudyIndex",
    components: {ScrollNotice, Book},
    data() {
      return {
        active: '1',
        menus: [
          {id: '1', label: "医学教材、书籍", route: "医学教材、书籍"},
          {id: '2', label: "医学图片图谱", route: "医学图片图谱"},
          {id: '3', label: "医学技术操作、实验科研操作视频", route: "医学技术操作、实验科研操作视频"},
          {id: '4', label: "见习实习视频", route: "见习实习视频"},
          {id: '5', label: "教学视频", route: "教学视频"},
          {id: '6', label: "手术视频", route: "手术视频"},
          {id: '7', label: "科普视频", route: "科普视频"},  
        ]
      };
    },
    mounted() {
      this.$router.push({name: "医学教材、书籍", query: {id: this.active}})
    },
    methods: {
      handleClick(id) {
        this.menus.forEach(menu => {
          if (menu.id === id) {
            this.$router.push({name: menu.route, query: {id: menu.id}})
          }
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .container {
    .el-container {
      width: 1200px;
      min-height: 800px;
      margin: 20px auto;

      .el-aside {
        margin-right: 20px;

        .study-menu {
          .el-menu-item {
            &.is-active {
              background-color: $green;
            }

          }
        }
      }


    }

  }
</style>
